var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "tableImpr",
            attrs: {
              title: "라인별 설비 목록",
              columns: _vm.gridImpr.columns,
              editable: _vm.editable && !_vm.disabled,
              data: _vm.data.dailyImproveModelList,
              merge: _vm.gridImpr.merge,
              usePaging: false,
              isExcelDown: false,
              filtering: false,
              columnSetting: false,
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props, col }) {
                  return [
                    col.name === "customCol"
                      ? [
                          _c(_vm.imprComponent, {
                            tag: "component",
                            attrs: {
                              col: col,
                              props: props,
                              inputEditable: _vm.editable && !_vm.disabled,
                              isImmShow: true,
                              requestContentsCols: _vm.requestContentsCols,
                              tableKeys: _vm.tableKeys,
                              ibmTaskTypeCd: "ITT0000070",
                              ibmTaskUnderTypeCd: "ITTU000120",
                            },
                            on: { imprChange: _vm.imprChange },
                          }),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }